<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <b-tabs v-model="tabIndex" content-class="mt-3" nav-class="font-weight-bold">
          <b-tab title="Элементы анкеты" active>
            <b-button variant="info" @click="editMeasurementUnit()">Создать элемент анкеты</b-button>
            <br /><br />
            <div v-if="error" class="alert alert-danger">
              {{error}}
            </div>
            <b-table striped hover :items="items | filterItems(searchRequest)" :fields="fields" ref="measurementUnitTable">
              <template #head(checkListTypeName)="data">
                {{ data.label }}
                <b-select v-model="searchRequest" :options="checkListTypeOptions" ></b-select>
              </template>
              <!-- A custom formatted column -->
              <template v-slot:cell(hideForDoctor)="data">
                <b-checkbox v-model="data.item.hideForDoctor" disabled switch />
              </template>
              <template v-slot:cell(buttons)="data">
                <h3>
                  <nobr>
                    <b-link @click="editMeasurementUnit(data.item)" ><b-icon variant="info" icon="pencil" size="lg" ></b-icon></b-link>
                    &nbsp;
                    <b-link @click="deleteMeasurementUnit(data.item.checkListId, data.item.checkListName)"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link>
                  </nobr>
                </h3>
              </template>
            </b-table>
            <b-modal id="checklistelement" hide-footer size="xl" title="Элемент анкеты">
              <CheckListEdit @save-success="afterSave" v-bind:error="error" v-bind:checklist="currentMeasurementUnit"></CheckListEdit>
            </b-modal>
          </b-tab>
          <b-tab title="Упорядочивание жалоб">
            <CheckListSortOrder></CheckListSortOrder>
          </b-tab>
        </b-tabs>
        <Loader v-bind:showLoader="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import {host} from "@/constants";
import CheckListEdit from "@/components/admin/checklist/CheckListEdit";
import CheckListSortOrder from "@/components/admin/checklist/CheckListSortOrder";

export default {
  name: "CheckListList",
  components: {
    CheckListSortOrder,
    CheckListEdit,
    Loader
  },
  computed: {
    searchRequest: {
      get() {
        return this.$store.getters.checkListSearchRequest
      },
      set(value) {
        this.$store.commit('updateCheckListSearchRequest', value)
      }
    }
  },
  filters: {
    filterItems: function (items, searchRequest) {
      if (searchRequest !== null && searchRequest !== "" && searchRequest !== -1) {
        return items.filter((el) => (el.checkListType === searchRequest))
      }
      return items
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'checkListId',
          label: 'Id',
          sortable: true
        },
        {
          key: 'checkListName',
          label: 'Название',
          sortable: true
        },
        {
          key: 'checkListCode',
          label: 'Код',
          sortable: true
        },
        {
          key: 'hideForDoctor',
          label: 'Скрыт для врача',
          sortable: true
        },
        {
          key: 'gender',
          label: 'Пол',
          sortable: true
        },
        {
          key: 'checkListTypeName',
          label: 'Тип элемента анкеты',
          sortable: true
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false
        }
      ],
      tabIndex: 0,
      items: [],
      loading: true,
      error: null,
      currentMeasurementUnit: null,
      checkListTypeOptions: []
    }
  },
  methods: {
    deleteMeasurementUnit(paramId, paramName) {
      this.error = null;
      if (confirm('Вы уверены, что хотите удалить единицу измерения "' + paramName + '"')) {
        this.loading = true;
        fetch(host + '/rest/checklist/' + paramId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.items = this.items.filter(item => item.checkListId !== paramId)
              } else {
                this.error = json.message;
              }
              this.loading = false

            }).catch(error => {
          this.error = error
          this.loading = false
        })
      }
    },
    editMeasurementUnit(item = null) {
      this.currentMeasurementUnit = item
      this.$bvModal.show('checklistelement')
      //this.$router.push('/checklistedit/' + item.checkListId)
    },
    fetchData() {
      fetch(host + '/rest/checklist', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.items = json.checklists
            this.loading = false
          })

      fetch(host + '/rest/dicts/checklisttype', {
        credentials: "include"
      })
          .then(response => response.json())
          .then(json => {
            this.checkListTypeOptions = [ { value: -1, text: 'Все значения'}]
            this.checkListTypeOptions = this.checkListTypeOptions.concat(json.options)
            this.loading = false
          })

    },
    afterSave() {
      this.$bvModal.hide('checklistelement')
      this.loading = true
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  }
}
</script>

<style scoped>

</style>