<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <b-list-group horizontal>
          <b-list-group-item :variant="currentGroup !== null && r.value === currentGroup.value ? 'info' : ''"
                             v-for="r in checkListGroupOptions" :key="r.value" button @click="setRname(r)">{{ r.text }}
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
    <div class="row" v-if="!currentGroup">
      <div class="col-12">
        Выберите анкету
      </div>
    </div>
    <div class="row" v-if="currentGroup">
      <div class="col-12 wrapper">
        <b-button @click="saveCurrentGroupData()" variant="info" :disabled="saving">
          Сохранить изменения
          <b-spinner v-if="saving" variant="light" small></b-spinner>
        </b-button>
            <drop-list
                :items="currentGroup.items"
                class="list"
                @reorder="$event.apply(currentGroup.items)"
            >
              <template v-slot:item="{item}">
                <drag class="item" :key="item.checkListId">{{item.checkListName}}</drag>
              </template>
              <template v-slot:feedback="{data}">
                <div class="item feedback" :key="data">{{data}}</div>
              </template>
            </drop-list>
      </div>
    </div>
  </div>
</template>

<script>
import {Drag, DropList} from "vue-easy-dnd";
import {host} from "@/constants";

export default {
  name: "CheckListSortOrder",
  data() {
    return {
      checkListGroupOptions: [],
      currentGroup: null,
      saving: false
    }
  },
  methods: {
    async fetchDicts() {
      let response = await fetch(host + '/rest/dicts/checklistgroup', {
        credentials: "include"
      })
      let json = await response.json()
      this.checkListGroupOptions = json.options

      // response  = await fetch(host + '/rest/dicts/checklisttype', {
      //   credentials: "include"
      // })
      // json = await response.json()
      // this.checkListTypeOptions = json.options

    },
    async fetchExtendedGroups() {
      let response = await fetch(host + '/rest/checklist/groups/extended', {
        credentials: "include"
      })
      let json = await response.json()
      for (let groupOption of this.checkListGroupOptions) {
        groupOption.items = json.groups.filter(el => (el.checkListGroupId === groupOption.value))
      }
    },
    async saveCurrentGroupData() {

      if (!this.currentGroup) return
      this.saving = true
      const groupName = this.currentGroup.text
      for (const [i, v] of this.currentGroup.items.entries()) {
        v.priorityReport = i
      }
      console.log(JSON.stringify(this.currentGroup))
      fetch(host + '/rest/checklist/groups/extended', {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(this.currentGroup),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {
              this.$messageToast('Приоритеты для группы ' + groupName)
            } else {
              this.$errorToast(json.resultCode + ' ' + json.message)
            }
            this.saving=false
          })
          .catch(error => {
            this.$errorToast(json.resultCode + ' ' + json.message)
            this.saving=false
          })
    },
    setRname(rubr = null) {
      this.currentGroup = rubr

    }
  },
  filters: {
    filterCurrentGroup() {

    }
  },
  async mounted() {
    await this.fetchDicts()
    await this.fetchExtendedGroups()
    console.log('Mounted SortOrder ' + JSON.stringify(this.checkListGroupOptions))
  },
  components: {
    DropList, Drag
  }
}
</script>

<style >
.v-application--wrap,
.v-content,
.v-content__wrap {
  height: 100%;
}

.drop-in {
  box-shadow: 0 0 10px rgba(0, 0, 255, 0.3);
}
</style>

<style scoped >

 .wrapper .list {
    border: none;
    margin: 2rem auto;
    width: 100%;

  }
 .wrapper .list .item {
   padding: 0.3rem;
   margin: 1rem;
   color: rgb(23, 162, 184);
   border: 1px solid rgb(23, 162, 184);
   border-radius: 3px;
  font-weight: bold;
   background-color: rgb(255,255,255);
   display: flex;
   align-items: center;
   justify-content: left;
 }
 .wrapper .list .item .feedback {
   background-color: rgb(255, 220, 220);
   border: 2px dashed black;
 }

 .wrapper .list .item .drag-image {
   background-color: rgb(220, 255, 220);
   transform: translate(-50%, -50%);
 }
</style>